import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ChartBarSquareIcon, UserIcon, DocumentMagnifyingGlassIcon, ArrowDownIcon, ArrowUpIcon, ArrowPathIcon, ClockIcon,  EnvelopeOpenIcon} from "@heroicons/react/20/solid";
import moment from "moment";
import InviteService from "src/services/invite.service";
import UpdateService from "src/services/update.service";
import { toast } from "react-toastify";
import UserService from "src/services/user.service";
import { getImageUrl } from "src/utils/common";
import { DocumentChartBarIcon, DocumentIcon, DocumentTextIcon, PaperClipIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import ReactApexChart from 'react-apexcharts';

const StakeholderDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = UserService.getCurrentUser();

  // const invites = useSelector((state) => state.Tasks.invites);
  const [invite, setInvite] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [updates, setUpdates] = useState([]);
  const [rejectModalShown, setRejectModalShown] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);

  //Rejecting the task
  const [rejectTask, setRejectTask] = useState(null);

  //activity logs
  const [activityLogs, setActivityLogs] = useState([]);
  const [activityLogModalShown, setActivityLogModalShown] = useState(false);
  // const [updateForLog, setUpdateForLog] = useState(null);
  const [taskForLog, setTaskForLog] = useState(null);

  useEffect(() => {
    getAllUpdates();
  }, []);

  const getAllUpdates = async () => {
    InviteService.getOne(user.project.id, id)
      .then((response) => {
        setTasks(response.data.tasks);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const accept = (index) => {
    setLoadingIndex(index);
    let update = tasks[index].updates[0];
    UpdateService.accept({ userId: user.id, update: update })
      .then((response) => {
        getAllUpdates();
        toast("Update accepted");
        setLoadingIndex(null);
      })
      .catch((error) => {
        toast("An error occured");
        setLoadingIndex(null);
      });
  };

  const reject = (task, message) => {
    let update = task.updates[0];

    UpdateService.reject({
      update: update,
      userId: user.id,
      message: message,
      task: task,
    })
      .then((response) => {
        getAllUpdates();
        toast("Update rejected");
        setRejectModalShown(false);
      })
      .catch((error) => {
        toast("An error occured");
        setRejectModalShown(false);
      });
  };

  const getActivityLogs = async (taskId) => {
    try {
      let response = await UpdateService.getActivityLogsForTask(taskId);
      setActivityLogs(response.data.filter((item) => moment(item.date).isSame(moment(new Date()), "month")));
    } catch (error) {
      console.log(error);
    }
  };

  const stats = [
    { id: 1, name: 'Total Iterations', stat: '17', icon: ArrowPathIcon, change: '4', changeType: 'increase' },
    { id: 2, name: 'Performance', stat: '58.16%', icon: EnvelopeOpenIcon, change: '5.4%', changeType: 'increase' },
    { id: 3, name: '% of Delayed Updates', stat: '24.57%', icon: ClockIcon, change: '3.2%', changeType: 'decrease' },
  ]
  
  const tabs = [
    { name: 'About', href: '#', icon: UserIcon, current: false },
    // { name: 'Activity Logs', href: '#', icon: ClipboardDocumentListIcon, current: false },
    { name: 'Claims Lens', href: '#', icon: DocumentMagnifyingGlassIcon, current: true },
    { name: 'Synergy Dash', href: '#', icon: ChartBarSquareIcon, current: false },
    { name: 'Stakeholder Agreements', href: '#', icon: PaperClipIcon, current: false },
  ]

  const files = [
    { id: 1, name: "Contract_A.pdf", type: "pdf", icon: DocumentIcon },
    { id: 2, name: "Agreement_B.docx", type: "word", icon: DocumentTextIcon },
    { id: 3, name: "Invoice_C.xlsx", type: "excel", icon: DocumentChartBarIcon },
    { id: 4, name: "Proposal_D.pdf", type: "pdf", icon: DocumentIcon },
  ];
  
  const chartOptions = {
    chart: {
      type: 'line',
      height: '100%',
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    stroke: {
      curve: 'straight',
      width: 2,
      colors: ['#007DE5'], // Pink-purple color
    },
    xaxis: {
      categories: ["Oct 15", "Oct 30", "Nov 15", "Nov 30"],
      labels: { style: { colors: '#6B7280' }, show: true },
      axisTicks: { show: true },
      axisBorder: { show: true },
    },
    yaxis: {
      labels: {
        style: { colors: '#6B7280' },
        formatter: (value) => `${value}%`, // Format Y-axis as percentage
      },
      axisTicks: { show: false },
      axisBorder: { show: false },
      max: 100, // Assuming percentage is from 0-100
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: { formatter: (value) => `${value}%` },
    },
    grid: { show: true },
    dataLabels: { enabled: true },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        gradientToColors: ['#007DE5'],
        stops: [0, 100],
      },
    },
  };

  const chartData = [
    {
      name: 'Collaboration',
      data: [42.9, 27.3, 53, 58.4], // Replace with your dynamic data
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const [currentTab, setCurrentTab] = useState('Claims Lens'); 
  return (
    <div>
    <div className="bg-white p-8 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">
      {/* Left Section */}
      <div className="w-full md:w-4/5">
        <div className="flex items-center space-x-0">
          <h2 className="text-2xl font-bold text-gray-900">Colin Framing LLC</h2>
          <img
            src={getImageUrl("/images/updatedetail/stakeholder_badge.svg")}
            className="w-[48px] h-[48px] object-contain cursor-pointer"
            alt="Badge"
          />
        </div>
        <p className="mt-2 text-gray-500">
          Framing Contractor Halifax providing wood and metal carpentry and
          construction service for residential homes.
        </p>
        <div className="flex flex-wrap gap-8 mt-6">
          <dl className="mt-5 w-full grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {stats.map((item) => (
              <div
                key={item.id}
                className="relative overflow-hidden rounded-lg bg-white px-3 pb-0 pt-5 shadow sm:px-6 sm:pt-6"
              >
                <dt>
                  <div className="absolute rounded-md bg-primaryColor p-3">
                    <item.icon aria-hidden="true" className="size-6 text-white" />
                  </div>
                  <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
                </dt>
                <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                  <p
                    className={classNames(
                      item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                      'ml-2 flex items-baseline text-sm font-semibold',
                    )}
                  >
                    {item.changeType === 'increase' ? (
                      <ArrowUpIcon aria-hidden="true" className="size-5 shrink-0 self-center text-green-500" />
                    ) : (
                      <ArrowDownIcon aria-hidden="true" className="size-5 shrink-0 self-center text-red-500" />
                    )}

                    <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                    {item.change}
                  </p>
                </dd>
              </div>
            ))}
          </dl>

        </div>
      </div>

      {/* Right Section */}
      <div className="w-full md:w-1/5 mt-8 md:mt-0 flex flex-col items-center border-l-2 border-gray-200 ml-10 pl-4">
        <h3 className="text-sm text-gray-500">Percentage of Complete Work</h3>
        <p className="text-2xl font-bold text-gray-900 mt-2">31h 14m</p>
        <p className="text-sm text-green-600 mt-1 bg-green-100 px-2 py-1 rounded-full">
          68.4%
        </p>
        <div className="mt-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-24 h-24 text-green-500"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <circle cx="12" cy="12" r="10" className="text-gray-200" />
            <path
              d="M12 2a10 10 0 00-4.33 19.096L12 12l7.47-7.47A9.965 9.965 0 0012 2z"
              className="text-green-500"
            />
          </svg>
        </div>
      </div>
    </div>

    
      <div className="sm:hidden mt-18 md:mt-0">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          defaultValue={tabs.find((tab) => tab.current).name}
          className="block w-full rounded-md border-gray-300 focus:border-primaryColor focus:ring-primaryColor"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>




      <div className="mt-6 bg-white  rounded-t-lg hidden sm:block">
        <div className="  p-2">
          <nav aria-label="Tabs" className="-mb-px bg-white mt-100 flex space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentTab(tab.name);
                }}
                aria-current={tab.name === currentTab ? 'page' : undefined}
                className={classNames(
                  tab.name === currentTab
                    ? 'border-primaryColor text-primaryColor'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium'
                )}
              >
                <tab.icon
                  aria-hidden="true"
                  className={classNames(
                    tab.name === currentTab ? 'text-bgPrimary-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 size-5'
                  )}
                />
                <span>{tab.name}</span>
              </a>
            ))}
          </nav>
        </div>
      </div>

      {/* Tab Content */}
      <div className="bg-white p-6 rounded-b-lg rounded-top-0 shadow-md mt-0">
        {currentTab === 'Claims Lens' && (
            <div className="bg-white mx-auto">
            <h3 className="text-lg font-light text-gray-800 mb-2">Claims Lens</h3>

            <div className=" grid grid-cols-2 gap-8">
              {/* Left Column */}
              <div>
                {/* Current Plan Section */}
                <div className="mb-6">
                  <p className="text-sm text-gray-500">Contract Type</p>
                  <p className="text-base font-medium text-gray-800">Lump Sum</p>
                </div>

                {/* Active Until Section */}
                <div className="mb-6">
                  <p className="text-sm text-gray-500">Signed on</p>
                  <p className="text-base font-medium text-gray-800">Dec 09, 2021</p>
                </div>

                {/* Stakeholder Category Section */}
                <div>
                  <div className="flex items-center gap-2 mt-2">
                  <p className="text-sm text-gray-500">Stakeholder Category</p>
                    <div className="inline-block px-2 py-1 text-sm font-medium text-white bg-primaryColor rounded-full">
                      Subcontractor
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <div className="flex items-center mb-4 gap-2">
                    <p className="text-sm font-semibold text-gray-500">Max Potential Claim Amount:</p>
                    <div className="inline-block px-2 py-1 text-sm font-semibold text-white bg-lime-600 rounded-full">
                      $ 45,600 CAD
                    </div>
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div>
                {/* Attention Section */}
                  {/* <div className="flex items-center mb-4 gap-2">
                    <p className="text-sm font-semibold text-gray-500">Potential Claim Amount:</p>
                    <div className="inline-block px-2 py-1 text-sm font-semibold text-white bg-lime-600 rounded-full">
                    72000$ CAD
                    </div>
                  </div> */}
                  <div className="rounded-md bg-yellow-50 mb-4 p-4">
                    <div className="flex">
                      <div className="shrink-0">
                        <ExclamationTriangleIcon aria-hidden="true" className="size-5 text-yellow-400" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>
                          Colin Framing LLC will potentially incurr a delay of <span className="font-bold">38 days</span> on the Complete Roof Framing activity.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                {/* Updates Section */}
                  <div>
                    <h4 className="sr-only">Status</h4>
                    <div aria-hidden="true" className="mt-6">
                      {/* <div className="overflow-hidden rounded-full bg-gray-200">
                                    <div style={{ width: '76%' }} className="h-2 rounded-full bg-orange-800" />
                      </div> */}
                      <div className="mt-6 hidden grid-cols-1 text-sm font-medium text-gray-600 sm:grid">
                        <div className="text-yellow-800">
                          <p>As per <span className="font-bold"> clause #2</span> in the contract, Your supplier maybe subject to liquidated damages of <span className="font-bold"> 1200$/day</span>.
                          Immediate action is required to address this issue.</p>
                          <p> The activity must be completed or resolved by <span className="font-bold"> March 27, 2026</span></p>
                          <p> 
                          The actual forecase is set to <span className="font-bold"> May 19, 2026</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        )}

        {currentTab === 'About' && (
        <>
          <h3 className="text-lg font-light text-gray-800 mb-2">Stakeholder Documents Details</h3>
          <div className="grid grid-cols-2 gap-4 mt-5 rounded-lg">
              <div  className="flex">
                <p className="text-md text-gray-800">Company Name :</p>
                <p className="text-md font-light ml-2 text-gray-500">Colin Framing LLC</p>
              </div>
              <div  className="flex">
                <p className="text-md text-gray-800">Company Type:</p>
                <p className="text-md font-light ml-2 text-gray-500">Supplier</p>
              </div>
              <div  className="flex">
                <p className="text-md text-gray-800">Company Address :</p>
                <p className="text-md font-light ml-2 text-gray-500">6375 Bayne St, Halifax, NS</p>
              </div>
              <div  className="flex">
                <p className="text-md text-gray-800">Company Country:</p>
                <p className="text-md font-light ml-2 text-gray-500">Canada</p>
              </div>
              <div  className="flex">
                <p className="text-md text-gray-800">Contact Name :</p>
                <p className="text-md font-light ml-2 text-gray-500">Alex Goodman</p>
              </div>
              <div  className="flex">
                <p className="text-md text-gray-800">Contact Role :</p>
                <p className="text-md font-light ml-2 text-gray-500">Head of Procurement </p>
              </div>
              <div  className="flex">
                <p className="text-md text-gray-800">Contact Email :</p>
                <p className="text-md font-light ml-2 text-gray-500">alex@colinframing.ca</p>
              </div>
              <div  className="flex">
                <p className="text-md text-gray-800">Contact Phone # :</p>
                <p className="text-md font-light ml-2 text-gray-500">+1 902-403-26298</p>
              </div>
              <div  className="flex">
                <p className="text-md text-gray-800">Joined Date :</p>
                <p className="text-md font-light ml-2 text-gray-500">15-09-2023</p>
              </div>
              <div  className="flex">
                <p className="text-md text-gray-800">Number of Projects worked with:</p>
                <p className="text-md font-light ml-2 text-gray-500">12</p>
              </div>
          </div>
          </>
        )}

        {currentTab === 'Activity Logs' && (
          <div>
            <h3 className="text-lg font-light text-gray-800 mb-2">Activity Logs</h3>
            <p className="mt-2 text-gray-500">View all actions performed on the platform.</p>
          </div>
        )}

        {currentTab === 'Synergy Dash' && (
          <div>
            <div className="bg-white">
                <h3 className="text-lg font-light text-gray-800 mb-2">Performance Over Time</h3>
                <ReactApexChart
                  options={chartOptions}
                  series={chartData}
                  type="line"
                  height="300"
                />
            </div>
          </div>
        )}

        {currentTab === 'Stakeholder Agreements' && (
          <>
            <h3 className="text-lg font-light text-gray-800 mb-2">Stakeholder Documents Details</h3>
            <p className="text-md font-light text-gray-500">
              Stakeholder Agreements pulled from <span className="font-bold"> Procore</span>
            </p>
            <div className="space-y-2 mt-5">
              {files.map((file) => (
                <div key={file.id} className="flex items-center space-x-3 cursor-pointer hover:bg-gray-100 p-2 rounded">
                  <div className="flex items-center">
                    <file.icon
                      aria-hidden="true"
                      className="-ml-0.5 mr-2 size-5 text-gray-700 group-hover:text-gray-500" // Adjust the margin here
                    />
                    <span className="text-base font-medium text-sm text-gray-700"> {file.name} </span>
                  </div>
                </div>
              ))}
            </div>
          </>

        )}
      </div>
    </div>
  );
};

export default StakeholderDetail;
