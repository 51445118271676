import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from "@headlessui/react";
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  PaperAirplaneIcon
} from "@heroicons/react/24/outline";
import { ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getImageUrl } from "src/utils/common";
import { setAuthState } from "src/redux/slices/AuthSlice";
import AuthService from "src/services/auth.service";
import UserService from "src/services/user.service";
import { PiAlignCenterVerticalSimpleThin } from "react-icons/pi";

// const plannerMainItems = [
//   { name: "Dashboard", href: "/planner/updater/overview", icon: HomeIcon, current: true },
//   { name: "Stakeholders", href: "/planner/stakeholders", icon: UsersIcon, current: false },
//   { name: "Projects", href: "#", icon: FolderIcon, current: false },
//   { name: "Schedule", href: "#", icon: CalendarIcon, current: false },
//   { name: "Documents", href: "#", icon: DocumentDuplicateIcon, current: false },
//   { name: "Reports", href: "#", icon: ChartPieIcon, current: false },
// ];
// const plannerSecondaryItems = [
//   { id: 1, name: "Heroicons", href: "#", initial: "H", current: false },
//   { id: 2, name: "Tailwind Labs", href: "#", initial: "T", current: false },
//   { id: 3, name: "Workcation", href: "#", initial: "W", current: false },
// ];

const stakeholderMenuItems = [
  {
    title: "Updates",
    path: "/stakeholder/updates",
    icon: "/images/sidebar/check.png",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SideBar = () => {
  let currentUser = UserService.getCurrentUser();
  const [plannerMainItems, setPlannerMainItems] = useState([
    { name: "Dashboard", href: "/planner/updater/overview", icon: HomeIcon, current: true },
    { name: "Stakeholders", href: "/planner/stakeholders", icon: UsersIcon, current: false },
    { name: "Projects", href: "#", icon: FolderIcon, current: false },
    { name: "Schedule", href: "#", icon: CalendarIcon, current: false },
    { name: "Documents", href: "#", icon: DocumentDuplicateIcon, current: false },
    { name: "Reports", href: "#", icon: ChartPieIcon, current: false },
    { name: "Invites", href: "/planner/stakeholders-invites", icon: PaperAirplaneIcon, current: false },
  ]);
  const [plannerSecondaryItems, setPlannerSecondaryItems] = useState([
    { id: 1, name: "Behavior", href: "#", initial: "H", current: false },
    { id: 2, name: "Generator", href: "#", initial: "T", current: false },
    { id: 3, name: "Schedule Chat", href: "#", initial: "W", current: false },
  ]);
  const [stakeholderMainItems, setStakeholderMainItems] = useState([
    { name: "Updates", href: "/stakeholder/updates", icon: HomeIcon, current: true },
    { name: "Projects", href: "#", icon: FolderIcon, current: false },
  ]);
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const plannerMainItemClicked = (index) => {
    let temp = [...plannerMainItems];
    for (let i = 0; i < temp.length; i++) {
      if (i === index) {
        temp[i].current = true;
      } else {
        temp[i].current = false;
      }
    }
    setPlannerMainItems(temp);
  };

  const plannerSecondaryItemClicked = (index) => {
    let temp = [...plannerSecondaryItems];
    for (let i = 0; i < temp.length; i++) {
      if (i === index) {
        temp[i].current = true;
      } else {
        temp[i].current = false;
      }
    }
    setPlannerSecondaryItems(temp);
  };

  return (
    <div className="w-[200px]">
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-[200px] lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
          <div className="flex h-16 shrink-0 items-center gap-4">
            <img alt="Your Company" src={getImageUrl("/images/common/white_logo.png")} className="h-10 w-auto" />
            <span className="font-bold text-[20px] text-white">Instaplan</span>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                {currentUser.planner ? (
                  <ul role="list" className="-mx-2 space-y-1">
                    {plannerMainItems.map((item, index) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:bg-gray-800 hover:text-white",
                            "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                          )}
                          onClick={() => plannerMainItemClicked(index)}
                        >
                          <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <ul role="list" className="-mx-2 space-y-1">
                    {stakeholderMainItems.map((item, index) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:bg-gray-800 hover:text-white",
                            "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                          )}
                          onClick={() => plannerMainItemClicked(index)}
                        >
                          <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              {currentUser.planner && (
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Your products</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {plannerSecondaryItems.map((team, index) => (
                      <li key={team.name}>
                        <Link
                          to={team.href}
                          className={classNames(
                            team.current
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:bg-gray-800 hover:text-white",
                            "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                          )}
                          // onClick={() => plannerSecondaryItemClicked(index)}
                        >
                          <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              )}
              <li className="-mx-6 mt-auto">
                <div
                  onClick={() => {
                    dispatch(setAuthState(false));
                    AuthService.logout();
                  }}
                  className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800 cursor-pointer"
                >
                  <img
                    alt=""
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    className="h-8 w-8 rounded-full bg-gray-800"
                  />
                  <span className="sr-only">Your profile</span>
                  <span aria-hidden="true">
                    {currentUser.planner ? currentUser.planner.name : currentUser.stakeholder.name}
                  </span>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-400 lg:hidden">
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon aria-hidden="true" className="h-6 w-6" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
        <a href="#">
          <span className="sr-only">Your profile</span>
          <img
            alt=""
            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            className="h-8 w-8 rounded-full bg-gray-800"
          />
        </a>
      </div>

      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">{/* Your content */}</div>
      </main>
    </div>
  );
};

export default SideBar;
