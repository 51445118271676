import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import BlueButton from "src/components/buttons/BlueButton";
import TableFooter from "src/components/TableFooter";
import InviteService from "src/services/invite.service";
import Pending from "./components/Pending";
import Accepted from "./components/Accepted";
import CodeService from "src/services/code.service";
import UserService from "src/services/user.service";
import { Link } from 'react-router-dom';

const Stakeholders = () => {
  const navigate = useNavigate();
  const [modalShown, setModalShown] = useState(false);
  const [codeArray, setCodeArray] = useState([]);
  const [invites, setInvites] = useState([]);
  const user = UserService.getCurrentUser();

  useEffect(() => {
    getAll();
    getCodes();
  }, []);

  const getAll = async () => {
    try {
      let response = await InviteService.getAll(user.project.id);
      setInvites(response.data);
    } catch (err) {
      console.log("Fetching invites error: ", err);
    }
  };

  const getCodes = async () => {
    try {
      let user = UserService.getCurrentUser();
      let res = await CodeService.getAll(user.id);
      setCodeArray(res.data);
    } catch (e) {
      console.log("🚀 ~ Fetching code error:", e);
    }
  };

  return (
    <div className="w-full h-full bg-[#F5F5FB] flex flex-col flex-auto ">
      <span className="font-bold text-[26px]">Stakeholders</span>
      <div className="mt-8 flow-root ">
        <div className="sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table className="w-full">
                <thead className="bg-gray-50 overflow-x-hidden h-[80px]">
                  <tr className=" border-b-[2px] border-borderColor ">
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                    >
                      #
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Stakeholder
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Package
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Review Status
                    </th>
                  </tr>
                </thead>
            <tbody className="w-full divide-y divide-gray-200 bg-white ">
              {invites.map((invite, index) => (
                <tr key={index} className="w-full">
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {index + 1}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div className="mx-auto flex justify-left">
                      {invite.invited && invite.invited.stakeholder ? (
                        <Link
                          to={`/planner/stakeholder/${invite.invited.stakeholder.id}`}  // Adjust this URL to match your routing
                          className="text-blue-500 hover:text-blue-700"
                        >
                          {invite.invited.stakeholder.name}
                        </Link>
                      ) : (
                        invite.email
                      )}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div className="mx-auto flex justify-left">
                      {invite.code}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div className="mx-auto flex justify-left">{invite.accepted ? <Accepted /> : <Pending />}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
  );
};

export default Stakeholders;
